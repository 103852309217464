import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Lightbox from 'react-image-lightbox';

import config from './config'
import { PhotoList } from './apigen'
import { api } from './api'
import './App.css';
import 'react-image-lightbox/style.css';

const previewResizedWidth = 250
const minPhotosPerRow = 3
const gap = 4

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    })
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize()
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []) // Empty array ensures that effect is only run on mount
    return windowSize
}

function Gallery() {
    const { galleryCode } = useParams()
    const [currentImage, setCurrentImage] = useState(0)
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
    const size = useWindowSize()

    const initialPhotoList: PhotoList = {
        total: 0,
        items: []
    }
    const [photoList, setPhotoList] = useState(initialPhotoList)

    useEffect(() => {
        if (!galleryCode) {
            return
        }

        api.listPhotos({
            sessionCode: galleryCode
        }).then((res) => {
            setPhotoList(res)
        }).catch()
    }, [galleryCode])

    let photoSet = []
    let i = 0

    if (!photoList.items) {
        return null
    }

    for (let p of photoList.items) {
        photoSet.push({
            srcFullsize: `${config.apiURL}/gallery/${galleryCode}/photos/${p.name}`,
            src: `${config.apiURL}/gallery/${galleryCode}/photos/${p.name}?thumbnail=true`,
            title: p.name ? p.name : "",
            idx: i,
            name: p.name ? p.name : "",
        })
        i++
    }

    let next = photoSet[currentImage + 1] || null
    let prev = photoSet[currentImage - 1] || null
    let p = photoSet[currentImage]

    let imgWidth = previewResizedWidth
    if (size.width < (minPhotosPerRow + 1) * gap + minPhotosPerRow * previewResizedWidth) {
        imgWidth = (size.width - (minPhotosPerRow + 1) * gap) / minPhotosPerRow
    }

    return (
        <>
            <div style={{textAlign: "center", padding: "10px"}}><a style={{ fontSize: "1.5em" }} href={`${config.apiURL}/gallery/${galleryCode}/photos-archive`}>Скачать все фотографии</a></div>
            <div style={{ marginTop: `${gap}px`, marginBottom: `${gap}px`, display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "space-around", gap: `${gap}px` }}>
                {photoList.items && photoSet.map(photo => (<img width={`${imgWidth}px`} style={{ flexGrow: 0 }} onClick={() => { setCurrentImage(photo.idx); setLightboxIsOpen(true) }} src={`${config.apiURL}/gallery/${galleryCode}/photos/${photo.name}?thumbnail=true`} alt={photo.name} />))}
            </div>

            {lightboxIsOpen && <Lightbox
                imagePadding={0}
                mainSrc={p.srcFullsize}
                nextSrc={next ? next.srcFullsize : undefined}
                prevSrc={prev ? prev.srcFullsize : undefined}
                imageTitle={p.title}
                onCloseRequest={() => { setCurrentImage(0); setLightboxIsOpen(false) }}
                onMovePrevRequest={() => { setCurrentImage(currentImage - 1) }}
                onMoveNextRequest={() => { setCurrentImage(currentImage + 1) }}
                animationDuration={200}
                toolbarButtons={[(<a href={`${config.apiURL}/gallery/${galleryCode}/photos/${p.name}?download=true`}>Скачать</a>)]}
            />}
        </>
    );
}

export default Gallery;