import React from 'react';
import './index.css';
import { render } from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import Gallery from './Gallery';
import reportWebVitals from './reportWebVitals';

render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="g/:galleryCode" element={<Gallery />} />
    </Routes>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
