/* tslint:disable */
/* eslint-disable */
/**
 * IxShot Web Gallery API
 * IxShot Web Gallery API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Photo } from './Photo';
import {
    PhotoFromJSON,
    PhotoFromJSONTyped,
    PhotoToJSON,
} from './Photo';

/**
 * 
 * @export
 * @interface PhotoList
 */
export interface PhotoList {
    /**
     * 
     * @type {number}
     * @memberof PhotoList
     */
    readonly total?: number;
    /**
     * 
     * @type {Array<Photo>}
     * @memberof PhotoList
     */
    items?: Array<Photo>;
}

/**
 * Check if a given object implements the PhotoList interface.
 */
export function instanceOfPhotoList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PhotoListFromJSON(json: any): PhotoList {
    return PhotoListFromJSONTyped(json, false);
}

export function PhotoListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(PhotoFromJSON)),
    };
}

export function PhotoListToJSON(value?: PhotoList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(PhotoToJSON)),
    };
}

